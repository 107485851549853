<template>
    <v-menu v-model="open" offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-bell-outline</v-icon>
                <v-badge
                    v-if="showBadge"
                    color="blue"
                    :content="notificacoesNaoLidasCount"
                />
            </v-btn>
        </template>
        <v-card>
            <v-card-title style="width: 800px;">Notificações</v-card-title>
            <v-list 
                three-line 
                class="notification-container" 
                @scroll.native="onScroll"
            >
                <div 
                    v-for="(item, i) in dados" 
                    :key="i" 
                    class="highlight-on-hover notification-item"
                >
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon color="blue darken-3">mdi-bell</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">
                                {{ item.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.text }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                {{ timeAgo(item.time) }}
                            </v-list-item-action-text>
                            <v-icon color="blue darken-3">
                            mdi-circle-medium
                            </v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </div>
                <v-progress-linear 
                    v-if="loadingMore" 
                    indeterminate 
                    color="blue" 
                />
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'Notifications',

    data: () => ({
        loading: false,
        open: false,
        showBadge: true,
        loadingMore: false,
        page: 1, 
        totalPages: 2, 
    }),

    watch: {
        open(v) {
            console.log('watch ', v)
            if (v) {
                this.showBadge = false;
                this.page = 1;
                this.dados = [];
                this.consultar();
            }
        },
    },

    computed: {
        ...mapState([
            'notificacoesNaoLidasCount',
            // 'configuracoes',
        ]),
    },

    methods: {
        ...mapMutations({
            setNotificacoesNaoLidasCount: 'setNotificacoesNaoLidasCount',
        }),

        consultar() {
            this.loadingMore = true;
            const data = { page: this.page };
            this.$http.post('notificacoes/get-by-user', data) 
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    // this.setNotificacoes(resp.data.data);

                    const newData = resp.data.data.map(e => ({
                        title: e.notificacao.title,
                        text: e.notificacao.text,
                        time: e.notificacao.created_at,
                    }));

                    this.dados = [...this.dados, ...newData];

                    this.totalPages = resp.data.meta.last_page;
                })
                .catch(() => this.notify('Não foi possivel atualizar', 'warning'))
                .finally(() => (this.loadingMore = false));
        },

        onScroll(event) {
            const bottomOfList = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 2;

            console.log(bottomOfList, !this.loadingMore, this.page < this.totalPages)

            if (bottomOfList && !this.loadingMore && this.page < this.totalPages) {
                this.page++;
                this.loadingMore = true;
                this.consultar();
            }
        },

        // timeAgo(time) {
        //     return this.moment(time).fromNow(); 
        // },

        timeAgo(time) {
            const now = this.moment();
            const diffInMinutes = now.diff(this.moment(time), 'minutes');
            
            if (diffInMinutes < 60) {
                return `há ${diffInMinutes}m`; // exibe "há Xm" para minutos
            }

            const diffInHours = now.diff(this.moment(time), 'hours');
            if (diffInHours < 24) {
                return `há ${diffInHours}h`; // exibe "há Xh" para horas
            }

            const diffInDays = now.diff(this.moment(time), 'days');
            if (diffInDays < 7) {
                return `há ${diffInDays}d`; // exibe "há Xd" para dias
            }

            return this.moment(time).format('DD/MM/YYYY'); // exibe a data completa se for há mais de uma semana
        }
    },
}
</script>

<style scoped>
.highlight-on-hover {
  transition: background-color 0.1s ease;
}

.highlight-on-hover:hover {
  /* background-color: #f0f0f0;  */
   transform: scale(1.02);
}

.notification-container {
    padding: 0;
    max-height: 300px; 
    overflow-x: auto; 
    background-color: #ddd;
}

.notification-item {
    background: #fff;
    border-radius: 10px;
    margin: 8px 10px;
}

.v-list {
  scrollbar-width: none; /* Firefox */
}

.v-list::-webkit-scrollbar {
  width: 0; /* Chrome, Safari, Opera */
  height: 0;
}
</style>
